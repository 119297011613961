import { useState, useEffect } from 'react';
import {
	Button,
	BackButton,
	TextMaskDate,
	TextMaskSSN,
	TextMaskState,
	TextMaskZipCode,
	PermissionsBox,
} from '../components';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import FilledInput from '@mui/material/FilledInput';
import Checkbox from '@mui/material/Checkbox';
import FormHelperText from '@mui/material/FormHelperText';
import {
	useAuthStore,
	useIovationStore,
	useLanguageStore,
	useAlertStore,
	useDocumentStore,
	useConnectStore,
} from '../stores';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import moment from 'moment';
import { AlertBox } from '../components';
import API from '../util/API';
import Loader from 'react-loader-spinner';
import { useForceSession, useForceBetaAccessIfPhylloRequired } from '../util/hooks';
import { isProd } from '../util/config';
import { fakeSSN, fakeDOB } from '../util/string';
import { randFirstName, randLastName, randStreetAddress, randCity, randStateAbbr, randZipCode } from '@ngneat/falso';

const listItems = [{ value: 'Make sure your adblocker is disabled.' }, { value: 'Check your network connection.' }];
const headline = 'There was an error loading the application.';

export default function Application() {
	useForceSession();
	useForceBetaAccessIfPhylloRequired();
	const { t, language } = useLanguageStore();
	const { alert } = useAlertStore();
	const { profile, refresh } = useAuthStore();
	const { documentClick, trackConsent, GRASSHOPPER_BANK_MASTER_SERVICE_AGREEMENT, CONNECT_TERMS } =
		useDocumentStore();
	const { requirePhyllo, markHasFailedNerveKyc, token, secret } = useConnectStore();
	const { initIovation, iovationBlocked, iovationConnected, resetParams, iovationBlackboxId } = useIovationStore();
	const [submitting, setSubmitting] = useState(false);
	const {
		handleSubmit,
		control,
		reset,
		formState: { errors },
	} = useForm();
	const navigate = useNavigate();

	const prefillValues = isProd
		? {}
		: {
				firstName: randFirstName({ withAccents: false }),
				middleName:
					Math.random() > 0.5
						? Math.random() > 0.5
							? randFirstName({ withAccents: false }) // 25% chance of having a middle name
							: randFirstName({ withAccents: false }).substring(0, 1) // 25% chance of having a middle initial
						: undefined, // 50% chance of having no middle name
				lastName: randLastName({ withAccents: false }),
				streetLine1: randStreetAddress(),
				city: randCity(),
				state: randStateAbbr(),
				postalCode: randZipCode().substr(0, 5),
				country: 'US',
				citizenship: 'US',
				tin: fakeSSN(),
				dateOfBirth: fakeDOB(),
		  };

	useEffect(() => {
		reset(prefillValues);
		initIovation();
	}, []);

	useEffect(() => {
		if (profile) {
			const { businessOwnership, kycStatus } = profile;
			if (kycStatus === 'PENDING_STEP_UP') {
				navigate('../identity-verification-step-up');
			} else if (kycStatus === 'EXCEPTION' || kycStatus === 'INVALID_DATA' || kycStatus === 'PENDING') {
				navigate('../identity-verification-exception');
			} else if (kycStatus === 'MANUAL_REVIEW') {
				navigate('../identity-verification-manual-review');
			} else if (kycStatus === 'DENIED') {
				markHasFailedNerveKyc();
				navigate('../identity-verification-denied');
			} else if (businessOwnership?.length > 0) {
				navigate('../download');
			}
		}
	}, [profile]);

	const onSubmit = async ({
		citizenship,
		dateOfBirth,
		firstName,
		middleName,
		lastName,
		suffixName,
		streetLine1,
		streetLine2,
		city,
		state,
		country,
		postalCode,
		tin,
		devProfile,
	}) => {
		if (language === 'es') {
			dateOfBirth = dateOfBirth.replace(/(\d\d)-(\d\d)-(\d\d\d\d)/, '$3-$2-$1');
		} else {
			dateOfBirth = dateOfBirth.replace(/(\d\d)-(\d\d)-(\d\d\d\d)/, '$3-$1-$2');
		}

		const age = moment().diff(dateOfBirth, 'years', true);
		if (age < 18) {
			alert(t(`Oops!`), t(`You must be at least 18 years old to get a Manifest account.`));
			return;
		}

		if (firstName) firstName = firstName.trim();
		if (middleName) middleName = middleName.trim();
		if (lastName) lastName = lastName.trim();
		if (suffixName) suffixName = suffixName.trim();
		if (streetLine1) streetLine1 = streetLine1.trim();
		if (streetLine2) streetLine2 = streetLine2.trim();
		if (city) city = city.trim();
		if (state) state = state.trim().toUpperCase();

		if (!isProd && devProfile !== 'none') {
			postalCode = devProfile;
		}

		const payload = {
			citizenship,
			dateOfBirth,
			firstName,
			middleName,
			lastName,
			suffixName,
			streetLine1,
			streetLine2,
			city,
			state,
			country,
			postalCode,
			tin,
			token,
			secret,
			iovationBlackboxId,
		};

		setSubmitting(true);

		try {
			const { success } = await API.post(`/banking/apply/verify-and-open-soleprop`, payload);
			await trackConsent([GRASSHOPPER_BANK_MASTER_SERVICE_AGREEMENT, CONNECT_TERMS], 'application');

			if (success) {
				navigate('../opening-account');
			} else {
				refresh();
			}
		} catch (e) {
			setSubmitting(false);
			alert('Oops!', e.message);
		}
	};

	const handleRetry = () => {
		resetParams();
		initIovation();
	};

	const citizenshipOptions = [{ label: 'United States', value: 'US' }];
	const countryOptions = [{ label: 'United States', value: 'US' }];
	const devProfileOptions = [
		{ label: '🟢  Approve', value: 'none' },
		{ label: '🟡 Step-Up  →  🟢 Approve', value: '00006' },
		{ label: '🟡 Step-Up  →  🟡 Manual Review', value: '00001' },
		{ label: '🟡 Step-Up  →  🔴 Deny', value: '00005' },
		{ label: '🟡 Manual Review', value: '99999' },
		{ label: '🔴 Deny', value: '00003' },
	];

	if (!iovationConnected && !iovationBlocked) {
		return (
			<div className="page-container centered">
				<div className="page">
					<Loader
						type="Oval"
						color="#dee3eb"
						height={100}
						width={100}
						style={{ alignSelf: 'center', marginTop: '100px', marginBottom: '20px' }}
					/>
					<p className="display-linebreak">{'Loading Application...'}</p>
				</div>
			</div>
		);
	}

	return (
		<div className="page-container top">
			<div className="form">
				{iovationBlocked && (
					<>
						<AlertBox headline={headline} listItems={listItems} />
						<Button style={{ width: 240, marginTop: 30, cursor: 'pointer' }} onClick={handleRetry}>
							{'Retry'}
						</Button>
					</>
				)}
				{iovationConnected && (
					<>
						<BackButton logout />
						<PermissionsBox />
						{!requirePhyllo && <h2>{t(`Complete your info.`)}</h2>}
						{requirePhyllo && <h2>{t(`You're in! Now, complete your info.`)}</h2>}
						<h4>{t(`Personal`)}</h4>
						<form onSubmit={handleSubmit(onSubmit)}>
							<Controller
								name="firstName"
								render={({ field }) => (
									<TextField
										{...field}
										autoFocus={!prefillValues.firstName}
										id="firstName"
										label={'First Name'}
										variant="filled"
										sx={{ my: 1, width: '100%' }}
										helperText={errors?.firstName?.message}
										error={!!errors?.firstName}
									/>
								)}
								control={control}
								defaultValue=""
								rules={{
									required: t('IsRequired', { field: 'First Name' }),
								}}
							/>
							<Controller
								name="middleName"
								render={({ field }) => (
									<TextField
										{...field}
										id="middleName"
										label={'Middle Name'}
										variant="filled"
										sx={{ my: 1, width: '100%' }}
										helperText={errors?.middleName?.message}
										error={!!errors?.middleName}
									/>
								)}
								control={control}
								defaultValue=""
							/>
							<Controller
								name="lastName"
								render={({ field }) => (
									<TextField
										{...field}
										id="lastName"
										label={'Last Name'}
										variant="filled"
										sx={{ my: 1, width: '100%' }}
										helperText={errors?.lastName?.message}
										error={!!errors?.lastName}
									/>
								)}
								control={control}
								defaultValue=""
								rules={{
									required: t('IsRequired', { field: 'Last Name' }),
								}}
							/>
							<Controller
								name="suffixName"
								render={({ field }) => (
									<TextField
										{...field}
										id="suffixName"
										label={'Suffix'}
										variant="filled"
										sx={{ my: 1, width: '100%' }}
										helperText={errors?.suffixName?.message}
										error={!!errors?.suffixName}
									/>
								)}
								control={control}
								defaultValue=""
							/>
							<Controller
								name="dateOfBirth"
								render={({ field }) => (
									<FormControl
										sx={{ my: 1, width: '100%' }}
										variant="filled"
										error={!!errors?.dateOfBirth}>
										<InputLabel htmlFor="dateOfBirth">
											Date of Birth ({t('DateEntryFormat')})
										</InputLabel>
										<FilledInput {...field} id="dateOfBirth" inputComponent={TextMaskDate} />
										{!!errors?.dateOfBirth && (
											<FormHelperText error={!!errors?.dateOfBirth}>
												{errors?.dateOfBirth?.message}
											</FormHelperText>
										)}
									</FormControl>
								)}
								control={control}
								defaultValue=""
								rules={{
									required: t('IsRequired', { field: 'Date of Birth' }),
									validate: {
										correct: v =>
											v.length !== 10
												? t('PleaseFollowFormat', { format: t('DateEntryFormat') })
												: true,
									},
								}}
							/>
							<Controller
								name="citizenship"
								render={({ field }) => (
									<FormControl
										sx={{ my: 1, width: '100%' }}
										variant="filled"
										error={!!errors?.password}>
										<InputLabel htmlFor="citizenship">{'Citizenship'}</InputLabel>
										<Select {...field} id="citizenship" label="Profile">
											{citizenshipOptions.map(({ label, value }) => (
												<MenuItem value={value} key={String(value)} disabled={!value}>
													{label}
												</MenuItem>
											))}
										</Select>
										{!!errors?.citizenship && (
											<FormHelperText error={!!errors?.citizenship}>
												{errors?.citizenship?.message}
											</FormHelperText>
										)}
									</FormControl>
								)}
								control={control}
								defaultValue="US"
								rules={{
									required: 'Citizenship is required.',
								}}
							/>
							<Controller
								name="tin"
								render={({ field }) => (
									<FormControl sx={{ my: 1, width: '100%' }} variant="filled" error={!!errors?.tin}>
										<InputLabel htmlFor="tin">{'Social Security Number'}</InputLabel>
										<FilledInput {...field} id="tin" inputComponent={TextMaskSSN} />
										{!!errors?.tin && (
											<FormHelperText error={!!errors?.tin}>
												{errors?.tin?.message}
											</FormHelperText>
										)}
									</FormControl>
								)}
								control={control}
								defaultValue=""
								rules={{
									required: t('IsRequired', { field: 'Social Security Number' }),
									validate: {
										correct: v =>
											v.length !== 11
												? t('EnterYourFull', { field: 'Social Security Number' })
												: true,
									},
								}}
							/>
							<h4>{t(`Physical Address`)}</h4>
							<Controller
								name="country"
								render={({ field }) => (
									<FormControl
										sx={{ my: 1, width: '100%' }}
										variant="filled"
										error={!!errors?.password}>
										<InputLabel htmlFor="country">{'Country'}</InputLabel>
										<Select {...field} id="country" label="Profile">
											{countryOptions.map(({ label, value }) => (
												<MenuItem value={value} key={String(value)}>
													{label}
												</MenuItem>
											))}
										</Select>
										{!!errors?.country && (
											<FormHelperText error={!!errors?.country}>
												{errors?.country?.message}
											</FormHelperText>
										)}
									</FormControl>
								)}
								control={control}
								defaultValue="US"
								rules={{
									required: 'Country is required.',
								}}
							/>
							<Controller
								name="streetLine1"
								render={({ field }) => (
									<TextField
										{...field}
										id="streetLine1"
										label={'Street Address 1'}
										variant="filled"
										sx={{ my: 1, width: '100%' }}
										helperText={errors?.streetLine1?.message}
										error={!!errors?.streetLine1}
										inputProps={{
											maxLength: 35,
										}}
									/>
								)}
								control={control}
								defaultValue=""
								rules={{
									required: t('IsRequired', { field: 'Street Address 1' }),
								}}
							/>
							<Controller
								name="streetLine2"
								render={({ field }) => (
									<TextField
										{...field}
										id="streetLine2"
										label={'Street Address 2'}
										variant="filled"
										sx={{ my: 1, width: '100%' }}
										helperText={errors?.streetLine2?.message}
										error={!!errors?.streetLine2}
										inputProps={{
											maxLength: 35,
										}}
									/>
								)}
								control={control}
								defaultValue=""
							/>
							<Controller
								name="city"
								render={({ field }) => (
									<TextField
										{...field}
										id="city"
										label={'City'}
										variant="filled"
										sx={{ my: 1, width: '100%' }}
										helperText={errors?.city?.message}
										error={!!errors?.city}
										inputProps={{
											maxLength: 18,
										}}
									/>
								)}
								control={control}
								defaultValue=""
								rules={{
									required: t('IsRequired', { field: 'City' }),
								}}
							/>
							<div style={{ display: 'flex', flexDirection: 'row' }}>
								<Controller
									name="state"
									render={({ field }) => (
										<FormControl
											sx={{ my: 1, mr: 1, width: '100%' }}
											variant="filled"
											error={!!errors?.state}>
											<InputLabel htmlFor="state">{'State'}</InputLabel>
											<FilledInput {...field} id="state" inputComponent={TextMaskState} />
											{!!errors?.state && (
												<FormHelperText error={!!errors?.state}>
													{errors?.state?.message}
												</FormHelperText>
											)}
										</FormControl>
									)}
									control={control}
									defaultValue=""
									rules={{
										required: t('IsRequired', { field: 'State' }),
										validate: {
											correct: v =>
												v.length !== 2 ? t('PleaseEnterYour', { field: 'state' }) : true,
										},
									}}
								/>
								<Controller
									name="postalCode"
									render={({ field }) => (
										<FormControl
											sx={{ my: 1, ml: 1, width: '100%' }}
											variant="filled"
											error={!!errors?.postalCode}>
											<InputLabel htmlFor="postalCode">{'ZIP Code'}</InputLabel>
											<FilledInput {...field} id="postalCode" inputComponent={TextMaskZipCode} />
											{!!errors?.postalCode && (
												<FormHelperText error={!!errors?.postalCode}>
													{errors?.postalCode?.message}
												</FormHelperText>
											)}
										</FormControl>
									)}
									control={control}
									defaultValue=""
									rules={{
										required: t('IsRequired', { field: 'ZIP Code' }),
										validate: {
											correct: v =>
												v.length !== 5 ? t('PleaseEnterYour', { field: 'zip code' }) : true,
										},
									}}
								/>
							</div>
							<h4>{t(`Agreements`)}</h4>
							<Controller
								name="termsAgreement"
								render={({ field }) => (
									<FormControl
										sx={{ my: 1, width: '100%' }}
										variant="filled"
										error={!!errors?.termsAgreement}>
										<div
											className="box"
											style={{
												height: 70,
												display: 'flex',
												flexDirection: 'row',
												alignItems: 'center',
												margin: 0,
											}}>
											<FormControlLabel
												control={
													<Checkbox {...field} id="termsAgreement" style={{ top: -12 }} />
												}
												label={
													<>
														I agree to Grasshpper Bank's{' '}
														<a
															href="#"
															onClick={documentClick(
																GRASSHOPPER_BANK_MASTER_SERVICE_AGREEMENT,
															)}
															target="_blank"
															rel="noreferrer">
															master service agreement
														</a>
														<br />
														and the{' '}
														<a
															href="#"
															onClick={documentClick(CONNECT_TERMS)}
															target="_blank"
															rel="noreferrer">
															Connect terms
														</a>
														.
													</>
												}
											/>
										</div>
										{!!errors?.termsAgreement && (
											<FormHelperText error={!!errors?.termsAgreement}>
												{errors?.termsAgreement?.message}
											</FormHelperText>
										)}
									</FormControl>
								)}
								control={control}
								defaultValue=""
								rules={{
									required: 'You must agree to continue.',
								}}
							/>

							{!isProd && (
								<>
									<h4>{t(`Sandbox`)}</h4>
									<Controller
										name="devProfile"
										render={({ field }) => (
											<FormControl
												sx={{ my: 1, width: '100%' }}
												variant="filled"
												error={!!errors?.password}>
												<InputLabel htmlFor="devProfile">{'Action'}</InputLabel>
												<Select {...field} id="devProfile" label="Profile">
													{devProfileOptions.map(({ label, value }) => (
														<MenuItem value={value} key={String(value)} disabled={!value}>
															{label}
														</MenuItem>
													))}
												</Select>
											</FormControl>
										)}
										control={control}
										defaultValue="none"
									/>
								</>
							)}

							<Button loading={submitting} type="submit" style={{ width: 240, marginTop: 60 }}>
								{'Submit'}
							</Button>
						</form>
					</>
				)}
			</div>
		</div>
	);
}
