import { useState } from 'react';
import { Button, BackButton, TextMaskMobileNumber } from '../components';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import FilledInput from '@mui/material/FilledInput';
import Checkbox from '@mui/material/Checkbox';
import FormHelperText from '@mui/material/FormHelperText';
import LinearProgress from '@mui/material/LinearProgress';
import { useToggle } from '../util/hooks';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useAuthStore, useLanguageStore, useAlertStore, useConnectStore, useDocumentStore } from '../stores';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import zxcvbn from 'zxcvbn';

export default function SignUp() {
	const { register } = useAuthStore();
	const { t } = useLanguageStore();
	const { alert } = useAlertStore();
	const { email, firstName, affiliateCode, userAddendum, appName } = useConnectStore();
	const { documentClick, trackConsent, TERMS_OF_SERVICE, PRIVACY_POLICY, PRIVACY_NOTICE, E_SIGN_POLICY } =
		useDocumentStore();
	const [submitting, setSubmitting] = useState(false);
	const [showPassword, toggleShowPassword] = useToggle(false);

	const {
		handleSubmit,
		control,
		formState: { errors },
	} = useForm();
	const navigate = useNavigate();

	const [score, setScore] = useState(0);
	const [scoreColor, setScoreColor] = useState('error');
	const [scoreProgress, setScoreProgress] = useState(0);
	const [scoreLabel, setScoreLabel] = useState(null);

	const onPasswordChange = event => {
		const password = event.target.value;
		let score = zxcvbn(password).score;
		if (score === 0 && password.length > 0) {
			score = 1;
		}
		setScore(score);

		if (password.length === 0) {
			setScoreLabel(null);
			setScoreColor('error');
			setScoreProgress(0);
		} else if (score === 0) {
			setScoreLabel(t('Too Weak'));
			setScoreColor('error');
			setScoreProgress(0);
		} else if (score === 1) {
			setScoreLabel(t('Too Weak'));
			setScoreColor('error');
			setScoreProgress(15);
		} else if (score === 2) {
			setScoreLabel(t('Good'));
			setScoreColor('warning');
			setScoreProgress(50);
		} else if (score === 3) {
			setScoreLabel(t('Strong'));
			setScoreColor('info');
			setScoreProgress(75);
		} else if (score === 4) {
			setScoreLabel(t('Show-off'));
			setScoreColor('success');
			setScoreProgress(100);
		}
	};

	const onSubmit = async form => {
		const nickname = form.nickname.trim();
		const email = form.email.trim();
		const mobileNumber = '+1' + form.mobileNumber.split('-').join('');
		const password = form.password;
		setSubmitting(true);

		try {
			await register({
				nickname,
				email,
				mobileNumber,
				password,
				affiliateCode,
			});
			await trackConsent([TERMS_OF_SERVICE, PRIVACY_POLICY, PRIVACY_NOTICE, E_SIGN_POLICY], 'sign-up');
			navigate('../verify-mobile');
		} catch (e) {
			setSubmitting(false);
			alert(t('Oops!'), t(e.message));
		}
	};

	return (
		<div className="page-container top">
			<div className="form">
				<BackButton />
				<h2>{t('Create a Manifest login.', { appName })}</h2>
				<form onSubmit={handleSubmit(onSubmit)}>
					<Controller
						name="nickname"
						render={({ field }) => (
							<TextField
								{...field}
								autoFocus={!firstName}
								id="nickname"
								label={t('What should we call you?')}
								variant="filled"
								sx={{ my: 1, width: '100%' }}
								helperText={errors?.nickname?.message}
								error={!!errors?.nickname}
							/>
						)}
						control={control}
						defaultValue={firstName}
						rules={{
							required: t('IsRequired', { field: t('Nickname') }),
						}}
					/>
					<Controller
						name="email"
						render={({ field }) => (
							<TextField
								{...field}
								id="email"
								label={t('Email Address')}
								variant="filled"
								sx={{ my: 1, width: '100%' }}
								helperText={errors?.email?.message}
								error={!!errors?.email}
							/>
						)}
						control={control}
						defaultValue={email}
						rules={{
							required: t('IsRequired', { field: t('Email Address') }),
							pattern: {
								value: /\S+@\S+\.\S+/,
								message: t('EnterValid', { field: t('Email Address') }),
							},
						}}
					/>
					<Controller
						name="mobileNumber"
						render={({ field }) => (
							<FormControl sx={{ my: 1, width: '100%' }} variant="filled" error={!!errors?.mobileNumber}>
								<InputLabel htmlFor="mobileNumber">{t('Mobile Number')}</InputLabel>
								<FilledInput {...field} id="mobileNumber" inputComponent={TextMaskMobileNumber} />
								{!!errors?.mobileNumber && (
									<FormHelperText error={!!errors?.mobileNumber}>
										{errors?.mobileNumber?.message}
									</FormHelperText>
								)}
							</FormControl>
						)}
						control={control}
						defaultValue={''}
						rules={{
							required: t('IsRequired', { field: t('Mobile Number') }),
							pattern: {
								value: /^[0-9]{3}-[0-9]{3}-[0-9]{4}$/i,
								message: t(`Please enter your 10-digit number.`),
							},
						}}
					/>
					<Controller
						name="password"
						render={({ field }) => (
							<FormControl
								sx={{ my: 1, width: '100%' }}
								variant="filled"
								error={!!errors?.password}
								onChange={onPasswordChange}>
								<InputLabel htmlFor="password">{t('Password')}</InputLabel>
								<FilledInput
									{...field}
									id="password"
									type={showPassword ? 'text' : 'password'}
									endAdornment={
										<InputAdornment position="end">
											<IconButton
												aria-label="toggle password visibility"
												onClick={toggleShowPassword}
												onMouseDown={event => event.preventDefault()}
												edge="end">
												{showPassword ? (
													<Visibility color="secondary" />
												) : (
													<VisibilityOff color="secondary" />
												)}
											</IconButton>
										</InputAdornment>
									}
								/>
								{score > 0 && (
									<LinearProgress variant="determinate" value={scoreProgress} color={scoreColor} />
								)}
								{(!!errors?.password || !!scoreLabel) && (
									<FormHelperText error={!!errors?.password}>
										{errors?.password?.message || scoreLabel}
									</FormHelperText>
								)}
							</FormControl>
						)}
						control={control}
						defaultValue=""
						rules={{
							required: t('IsRequired', { field: t('Password') }),
							validate: {
								correct: v =>
									zxcvbn(v).score < 2 ? `${t(`Password strength:`)} ${t(`Too Weak`)}` : true,
							},
						}}
					/>
					<Controller
						name="termsAgreement"
						render={({ field }) => (
							<FormControl
								sx={{ my: 1, width: '100%' }}
								variant="filled"
								error={!!errors?.termsAgreement}>
								<div
									className="box"
									style={{
										height: 70,
										display: 'flex',
										flexDirection: 'row',
										alignItems: 'center',
										margin: 0,
									}}>
									<FormControlLabel
										control={<Checkbox {...field} id="termsAgreement" style={{ top: -12 }} />}
										label={
											<div>
												I agree to the{' '}
												<a
													href="#"
													onClick={documentClick(TERMS_OF_SERVICE)}
													target="_blank"
													rel="noreferrer">
													terms of service
												</a>
												,{' '}
												<a
													href="#"
													onClick={documentClick(PRIVACY_POLICY)}
													target="_blank"
													rel="noreferrer">
													privacy policy
												</a>
												,<br />
												<a
													href="#"
													onClick={documentClick(PRIVACY_NOTICE)}
													target="_blank"
													rel="noreferrer">
													privacy notice
												</a>
												, and{' '}
												<a
													href="#"
													onClick={documentClick(E_SIGN_POLICY)}
													target="_blank"
													rel="noreferrer">
													e-sign policy
												</a>
												.{/* Add this back in if we're doing whitelabel user addendums */}
												{/* {!userAddendum && t('TermsOfServicePost')}
												{!!userAddendum && (
													<>
														{t('AgreementBetweenFinal')}
														<a
															href="#"
															onClick={documentClick(userAddendum)}
															target="_blank"
															rel="noreferrer">
															{t('UserAddendumLink')}
														</a>
														{t('TermsOfServicePost')}
													</>
												)} */}
											</div>
										}
									/>
								</div>
								{!!errors?.termsAgreement && (
									<FormHelperText error={!!errors?.termsAgreement}>
										{errors?.termsAgreement?.message}
									</FormHelperText>
								)}
							</FormControl>
						)}
						control={control}
						defaultValue=""
						rules={{
							required: 'You must agree to continue.',
						}}
					/>

					<Button loading={submitting} type="submit" style={{ width: 240, marginTop: 60 }}>
						{t('Submit')}
					</Button>
				</form>
			</div>
		</div>
	);
}
