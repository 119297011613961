import create from 'zustand';
import axios from 'axios';
import { useAuthStore, useLanguageStore } from '.';
import API from '../util/API';

export default create((set, get) => ({
	loading: false,
	document: null,
	pdfUrl: null,
	TERMS_OF_SERVICE: 'terms-of-service',
	PRIVACY_POLICY: 'privacy-policy',
	PRIVACY_NOTICE: 'privacy-notice',
	E_SIGN_POLICY: 'e-sign-policy',
	GRASSHOPPER_BANK_MASTER_SERVICE_AGREEMENT: 'grasshopper-master-service-agreement',
	CONNECT_TERMS: 'connect-terms',
	viewedDocuments: [],
	documentClick: slug => {
		return e => {
			e.preventDefault();
			const { loadDocument } = get();
			loadDocument(slug);
		};
	},
	loadDocument: async slug => {
		const { viewedDocuments } = get();
		const { contentfulLocale } = useLanguageStore.getState();
		const query = `
        {
          documentCollection(where: {slug: "${slug}"}) {
            items {
			  title (locale: "${contentfulLocale}")
              body (locale: "${contentfulLocale}")
			  body2 (locale: "${contentfulLocale}")
			  body3 (locale: "${contentfulLocale}")
			  body4 (locale: "${contentfulLocale}")
			  body5 (locale: "${contentfulLocale}")
			  body6 (locale: "${contentfulLocale}")
			  body7 (locale: "${contentfulLocale}")
			  body8 (locale: "${contentfulLocale}")
			  pdf (locale: "${contentfulLocale}") {
				url
			  }
            }
          }
        }
        `;

		try {
			set({ loading: true, viewedDocuments: [...viewedDocuments, slug] });

			const {
				data: {
					data: {
						documentCollection: { items },
					},
				},
			} = await axios({
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: 'Bearer Ue56460ccUWnR41JzQ7lusbvvBXb1XgggIyyoDujWgY',
				},
				url: `https://graphql.contentful.com/content/v1/spaces/yxxefa9lxddz/`,
				data: JSON.stringify({ query }),
			});

			if (items.length > 0) {
				const { body, body2, body3, body4, body5, body6, body7, body8, pdf } = items[0];
				if (pdf) {
					const { url: pdfUrl } = pdf;
					set({ pdfUrl });
				} else {
					const document = [body, body2, body3, body4, body5, body6, body7, body8].join('\n');
					set({ document });
				}
				set({ loading: false });
			} else {
				set({ loading: false });
			}
		} catch (e) {
			console.log(e);
		}
	},
	dismiss: () => {
		set({ document: null, pdfUrl: null, loading: false });
	},
	loadMarkdown: document => {
		set({ document, loading: false });
	},
	trackConsent: async (slugs, feature, metadata) => {
		const { profile } = useAuthStore.getState();
		const { viewedDocuments } = get();
		const [businessProfileObject] = profile?.businessProfiles ?? [];
		const businessProfileId = businessProfileObject?.businessProfile?.id;

		const confirmedTermsPromises = slugs.map(slug => {
			const consentPayload = {
				slug,
				viewed: viewedDocuments.includes(slug),
				provider: 'contentful',
				context: {
					platform: 'connect',
					feature,
					businessProfileId: businessProfileId ?? undefined,
				},
				metadata,
			};
			return API.post('/monitoring/documentConsent', consentPayload);
		});

		await Promise.all(confirmedTermsPromises);
	},
}));
