import { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { Button, BackButton, TextMaskSSN, TextMaskState, TextMaskZipCode } from '../components';
import {
	TextField,
	FormControl,
	FormHelperText,
	FormControlLabel,
	Checkbox,
	RadioGroup,
	Radio,
	InputLabel,
	FilledInput,
	Select,
	MenuItem,
} from '@mui/material';
import { useForm, Controller, useWatch } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useConnectStore, useLanguageStore, useDocumentStore } from '../stores';
import { isProd } from '../util/config';

export default function BankInfo() {
	const {
		handleSubmit,
		control,
		formState: { errors },
		setError,
		clearErrors,
		reset,
	} = useForm();
	const navigate = useNavigate();
	const { t } = useLanguageStore();
	const { connectAch } = useConnectStore();
	const [submitting, setSubmitting] = useState(false);
	const accountNumber = useWatch({ control, name: 'accountNumber' });
	const routingNumber = useWatch({ control, name: 'routingNumber' });
	const [institutionName, setInstitutionName] = useState();
	const [institutionError, setInstitutionError] = useState(false);
	const [dataRoutingNumber, setDataRoutingNumber] = useState('');
	const { documentClick, trackConsent, CONNECT_TERMS } = useDocumentStore();

	const countryOptions = [{ label: t('United States'), value: 'US' }];

	const prefillValues = isProd
		? {}
		: {
				nameOnAccount: 'Alice Alison',
				routingNumber: '111000025',
				accountNumber: '12341234',
				verifyAccountNumber: '12341234',
				accountType: 'checking',
				tin: '123456789',
				firstName: 'Alice',
				lastName: 'Alison',
				streetLine1: '123 Main St',
				city: 'Austin',
				state: 'TX',
				postalCode: '78704',
		  };

	useEffect(() => {
		reset(prefillValues);
	}, []);

	useEffect(() => {
		if (routingNumber?.length === 9) {
			setDataRoutingNumber(routingNumber);
		} else {
			setDataRoutingNumber(null);
			setInstitutionName(null);
		}
	}, [routingNumber]);

	const loadinstitutionName = async () => {
		const {
			data: { code, name },
		} = await axios({
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
			},
			url: `https://www.routingnumbers.info/api/name.json?rn=${dataRoutingNumber}`,
		});
		if (code === 200) {
			setInstitutionName(name);
			setInstitutionError(false);
			clearErrors('routingNumber');
		} else {
			setInstitutionName(null);
			setInstitutionError(true);
			setError('routingNumber', {
				type: 'manual',
				message: t('Please check your routing number.'),
			});
		}
	};

	useEffect(() => {
		if (dataRoutingNumber?.length === 9) {
			loadinstitutionName();
		}
	}, [dataRoutingNumber]);

	const onSubmit = async ({
		nameOnAccount,
		accountNumber,
		accountType,
		routingNumber,
		firstName,
		lastName,
		streetLine1,
		streetLine2,
		city,
		state,
		postalCode,
		tin,
	}) => {
		setSubmitting(true);

		try {
			await connectAch({
				institutionName,
				nameOnAccount,
				accountNumber,
				accountType,
				routingNumber,
				firstName,
				lastName,
				streetLine1,
				streetLine2,
				city,
				state,
				postalCode,
				tin,
			});
			await trackConsent([CONNECT_TERMS], 'connect-ach');
			navigate('../complete');
		} catch (e) {
			setSubmitting(false);
		}
	};

	const termsLabel = (
		<>
			I agree to Manifest's&nbsp;
			<a href="https://legal.manifestfinancial.com/ach-service-terms" target="_blank" rel="noreferrer">
				ACH terms
			</a>
			.
		</>
	);

	return (
		<div className="page-container top">
			<div className="form">
				<BackButton />
				<h2>{t('Enter your banking details.')}</h2>
				<h4>{t(`Bank Info`)}</h4>
				<form onSubmit={handleSubmit(onSubmit)}>
					<Controller
						name="nameOnAccount"
						render={({ field }) => (
							<TextField
								{...field}
								autoFocus={!prefillValues.nameOnAccount}
								id="nameOnAccount"
								label={t('Name On Account')}
								variant="filled"
								sx={{ my: 1, width: '100%' }}
								helperText={errors?.nameOnAccount?.message}
								error={!!errors?.nameOnAccount}
								inputProps={{
									maxLength: 40,
								}}
							/>
						)}
						control={control}
						defaultValue=""
						rules={{
							required: t('IsRequired', { field: t('Name On Account') }),
						}}
					/>

					<Controller
						name="accountNumber"
						render={({ field }) => (
							<TextField
								{...field}
								id="accountNumber"
								label={t('Account Number')}
								variant="filled"
								sx={{ my: 1, width: '100%' }}
								helperText={errors?.accountNumber?.message}
								error={!!errors?.accountNumber}
								inputProps={{ maxLength: 17, inputMode: 'numeric', pattern: '[0-9]*' }}
							/>
						)}
						control={control}
						defaultValue=""
						rules={{
							required: t('IsRequired', { field: t('Account Number') }),
						}}
					/>
					<Controller
						name="verifyAccountNumber"
						render={({ field }) => (
							<TextField
								{...field}
								id="verifyAccountNumber"
								label={t('Verify Account Number')}
								variant="filled"
								sx={{ my: 1, width: '100%' }}
								helperText={errors?.verifyAccountNumber?.message}
								error={!!errors?.verifyAccountNumber}
								inputProps={{ maxLength: 17, inputMode: 'numeric', pattern: '[0-9]*' }}
							/>
						)}
						control={control}
						defaultValue=""
						rules={{
							required: t('IsRequired', { field: t('Verify Account Number') }),
							validate: value => value === accountNumber || t('Your account numbers do not match.'),
						}}
					/>
					<Controller
						name="routingNumber"
						render={({ field }) => (
							<TextField
								{...field}
								id="routingNumber"
								label={t('Routing Number')}
								variant="filled"
								sx={{ my: 1, width: '100%' }}
								helperText={errors?.routingNumber?.message || institutionName || ' '}
								error={!!errors?.routingNumber}
								inputProps={{ maxLength: 9, inputMode: 'numeric', pattern: '[0-9]*' }}
							/>
						)}
						control={control}
						defaultValue=""
						rules={{
							required: t('IsRequired', { field: t('Routing Number') }),
							validate: {
								institutionError: v =>
									institutionError ? t('Please check your routing number.') : true,
								correct: v => (v.length !== 9 ? t('Please enter your 9-digit routing number.') : true),
							},
						}}
					/>

					<Controller
						name="accountType"
						render={({ field }) => (
							<>
								<div
									className="box"
									style={{ height: 56, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
									<FormControl {...field} component="fieldset">
										<RadioGroup
											aria-label="accountType"
											name="radio-buttons-group"
											row
											defaultValue={prefillValues.accountType}>
											<FormControlLabel
												value="checking"
												control={<Radio />}
												label={t('CheckingTypeAch')}
											/>
											<FormControlLabel
												value="savings"
												control={<Radio />}
												label={t('Savings')}
											/>
										</RadioGroup>
									</FormControl>
								</div>
								{!!errors?.accountType && (
									<FormControl>
										<FormHelperText error={!!errors?.accountType}>
											{errors?.accountType?.message}
										</FormHelperText>
									</FormControl>
								)}
							</>
						)}
						control={control}
						defaultValue=""
						rules={{
							required: t('IsRequired', { field: t('Account Type') }),
						}}
					/>
					<h4>{t(`Personal Info`)}</h4>
					<Controller
						name="firstName"
						render={({ field }) => (
							<TextField
								{...field}
								autoFocus
								id="firstName"
								label={t('First Name')}
								variant="filled"
								sx={{ my: 1, width: '100%' }}
								helperText={errors?.firstName?.message}
								error={!!errors?.firstName}
							/>
						)}
						control={control}
						defaultValue=""
						rules={{
							required: t('IsRequired', { field: t('First Name') }),
						}}
					/>
					<Controller
						name="lastName"
						render={({ field }) => (
							<TextField
								{...field}
								id="lastName"
								label={t('Last Name')}
								variant="filled"
								sx={{ my: 1, width: '100%' }}
								helperText={errors?.lastName?.message}
								error={!!errors?.lastName}
							/>
						)}
						control={control}
						defaultValue=""
						rules={{
							required: t('IsRequired', { field: t('Last Name') }),
						}}
					/>
					<Controller
						name="tin"
						render={({ field }) => (
							<FormControl sx={{ my: 1, width: '100%' }} variant="filled" error={!!errors?.tin}>
								<InputLabel htmlFor="tin">{t('Social Security Number')}</InputLabel>
								<FilledInput {...field} id="tin" inputComponent={TextMaskSSN} />
								{!!errors?.tin && (
									<FormHelperText error={!!errors?.tin}>{errors?.tin?.message}</FormHelperText>
								)}
							</FormControl>
						)}
						control={control}
						defaultValue=""
						rules={{
							required: t('IsRequired', { field: t('Social Security Number') }),
							validate: {
								correct: v =>
									v.length !== 11 ? t('EnterYourFull', { field: t('Social Security Number') }) : true,
							},
						}}
					/>
					<Controller
						name="streetLine1"
						render={({ field }) => (
							<TextField
								{...field}
								id="streetLine1"
								label={t('Street Address 1')}
								variant="filled"
								sx={{ my: 1, width: '100%' }}
								helperText={errors?.streetLine1?.message}
								error={!!errors?.streetLine1}
								inputProps={{
									maxLength: 35,
								}}
							/>
						)}
						control={control}
						defaultValue=""
						rules={{
							required: t('IsRequired', { field: t('Street Address 1') }),
						}}
					/>
					<Controller
						name="streetLine2"
						render={({ field }) => (
							<TextField
								{...field}
								id="streetLine2"
								label={t('Street Address 2')}
								variant="filled"
								sx={{ my: 1, width: '100%' }}
								helperText={errors?.streetLine2?.message}
								error={!!errors?.streetLine2}
								inputProps={{
									maxLength: 35,
								}}
							/>
						)}
						control={control}
						defaultValue=""
					/>
					<Controller
						name="city"
						render={({ field }) => (
							<TextField
								{...field}
								id="city"
								label={t('City')}
								variant="filled"
								sx={{ my: 1, width: '100%' }}
								helperText={errors?.city?.message}
								error={!!errors?.city}
								inputProps={{
									maxLength: 18,
								}}
							/>
						)}
						control={control}
						defaultValue=""
						rules={{
							required: t('IsRequired', { field: t('City') }),
						}}
					/>
					<div style={{ display: 'flex', flexDirection: 'row' }}>
						<Controller
							name="state"
							render={({ field }) => (
								<FormControl
									sx={{ my: 1, mr: 1, width: '100%' }}
									variant="filled"
									error={!!errors?.state}>
									<InputLabel htmlFor="state">{t('State')}</InputLabel>
									<FilledInput {...field} id="state" inputComponent={TextMaskState} />
									{!!errors?.state && (
										<FormHelperText error={!!errors?.state}>
											{errors?.state?.message}
										</FormHelperText>
									)}
								</FormControl>
							)}
							control={control}
							defaultValue=""
							rules={{
								required: t('IsRequired', { field: 'State' }),
								validate: {
									correct: v => (v.length !== 2 ? t('PleaseEnterYour', { field: 'state' }) : true),
								},
							}}
						/>
						<Controller
							name="postalCode"
							render={({ field }) => (
								<FormControl
									sx={{ my: 1, ml: 1, width: '100%' }}
									variant="filled"
									error={!!errors?.postalCode}>
									<InputLabel htmlFor="postalCode">{t('ZIP Code')}</InputLabel>
									<FilledInput {...field} id="postalCode" inputComponent={TextMaskZipCode} />
									{!!errors?.postalCode && (
										<FormHelperText error={!!errors?.postalCode}>
											{errors?.postalCode?.message}
										</FormHelperText>
									)}
								</FormControl>
							)}
							control={control}
							defaultValue=""
							rules={{
								required: t('IsRequired', { field: 'ZIP Code' }),
								validate: {
									correct: v => (v.length !== 5 ? t('PleaseEnterYour', { field: 'zip code' }) : true),
								},
							}}
						/>
					</div>
					<h4>{t(`Agreements`)}</h4>
					<Controller
						name="termsAgreement"
						render={({ field }) => (
							<FormControl
								sx={{ my: 1, width: '100%' }}
								variant="filled"
								error={!!errors?.termsAgreement}>
								<div
									className="box"
									style={{
										height: 56,
										display: 'flex',
										flexDirection: 'row',
										alignItems: 'center',
										margin: 0,
									}}>
									<FormControlLabel
										control={<Checkbox {...field} id="termsAgreement" />}
										label={
											<>
												{t('TermsOfServicePre')}
												<a
													href="#"
													onClick={documentClick(CONNECT_TERMS)}
													target="_blank"
													rel="noreferrer">
													Connect terms
												</a>
												.
											</>
										}
									/>
								</div>
								{!!errors?.termsAgreement && (
									<FormHelperText error={!!errors?.termsAgreement}>
										{errors?.termsAgreement?.message}
									</FormHelperText>
								)}
							</FormControl>
						)}
						control={control}
						defaultValue=""
						rules={{
							required: 'You must agree to continue.',
						}}
					/>

					<Button loading={submitting} type="submit" style={{ width: 240, marginTop: 60 }}>
						{t('Submit')}
					</Button>
				</form>
			</div>
		</div>
	);
}
